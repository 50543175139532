import { patch } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import {
  accountReactivationAttributesRequestSchema,
  accountReactivationAttributesResponseSchema,
  type AccountReactivationErrorResponse,
} from "../types";

const editAccountReactivationRequestSchema = z.object({
  data: z.object({
    type: z.literal("reactivation-request"),
    id: z.string(),
    attributes: accountReactivationAttributesRequestSchema,
  }),
});

const editAccountReactivationResponseSchema = z.object({
  data: z.object({
    type: z.literal("reactivation-request"),
    id: z.string(),
    attributes: accountReactivationAttributesResponseSchema.extend({
      firebaseToken: z.string().optional(),
    }),
  }),
});

const accountReactivationEditParamsSchema = z
  .object({
    id: z.string(),
  })
  .merge(accountReactivationAttributesRequestSchema);

export type EditAccountReactivationRequest = z.infer<typeof editAccountReactivationRequestSchema>;
export type EditAccountReactivationResponse = z.infer<typeof editAccountReactivationResponseSchema>;

export type EditAccountReactivationParams = z.infer<typeof accountReactivationEditParamsSchema>;

export function useEditAccountReactivationRequest(
  options: UseMutationOptions<
    EditAccountReactivationResponse,
    AxiosError<AccountReactivationErrorResponse>,
    EditAccountReactivationParams
  > = {}
) {
  return useMutation({
    mutationFn: async (data) => {
      const response = await patch({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/reactivation-requests/${data.id}`,
        data: {
          data: {
            id: data.id,
            type: "reactivation-request",
            attributes: {
              phone: data.phone,
              email: data.email,
              phoneOtp: data.phoneOtp,
              emailOtp: data.emailOtp,
            },
          },
        },
        requestSchema: editAccountReactivationRequestSchema,
        responseSchema: editAccountReactivationResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.EDIT_ACCOUNT_REACTIVATION_REQUEST_FAILURE,
    },
    ...options,
  });
}
