import { Span, Text } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";
import { OtpField } from "@src/appV2/lib/FormFields/OtpField";
import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";

interface VerifyOtpFormProps {
  onSubmit: (formData: { otp: string }) => Promise<void>;
  id: string;
  phoneNumber?: string;
  email?: string;
}

function VerifyOtp(props: VerifyOtpFormProps, ref: React.Ref<HTMLFormElement>) {
  const { onSubmit, id, phoneNumber, email } = props;
  const { handleSubmit } = useFormContext<{ otp: string }>();

  return (
    <Box
      ref={ref}
      component="form"
      id={id}
      sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}
      onSubmit={handleSubmit(async (formData) => {
        await onSubmit(formData);
      })}
    >
      <Text sx={{ pb: 1 }}>
        We&apos;ve just sent a verification code to{" "}
        <Span sx={{ whiteSpace: "nowrap" }}>
          <b>{phoneNumber ?? email}</b>
        </Span>
        .
      </Text>
      <OtpField name="otp" />
    </Box>
  );
}

export const VerifyOtpForm = forwardRef<HTMLFormElement, VerifyOtpFormProps>(VerifyOtp);
