import { IonPage } from "@ionic/react";
import { type ReactElement } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { ACCOUNT_REACTIVATION_SUCCESS_PATH } from "./paths";
import { ReactivateEmailPage } from "./ReactivateEmailPage";
import { ReactivatePhonePage } from "./ReactivatePhonePage";
import { ReactivateSuccessPage } from "./ReactivateSuccessPage";
import { AccountReactivationMethod } from "./types";

export function AccountReactivationRoutes(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/${AccountReactivationMethod.PHONE}`}>
        <IonPage>
          <ReactivatePhonePage />
        </IonPage>
      </Route>
      <Route exact path={`${match.url}/${AccountReactivationMethod.EMAIL}`}>
        <IonPage>
          <ReactivateEmailPage />
        </IonPage>
      </Route>
      <Route exact path={`${match.url}/${ACCOUNT_REACTIVATION_SUCCESS_PATH}`}>
        <IonPage>
          <ReactivateSuccessPage />
        </IonPage>
      </Route>
      <Route exact path={match.url}>
        <Redirect to={`${match.url}/${AccountReactivationMethod.PHONE}`} />
      </Route>
    </Switch>
  );
}
