import { Image, LoadingButton, Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import cbhBunnyLogo from "@src/appV2/lib/assets/images/cbhBunny.svg";
import { useHistory } from "react-router-dom";

import { RootPaths } from "../App/paths";
import { useLegacyStateContext } from "../LegacyStateBridge/context";
import { AppBarHeader, PageWithHeader } from "../lib";

export function ReactivateSuccessPage() {
  const history = useHistory();
  const {
    session: { authStateHasLoaded },
  } = useLegacyStateContext();

  return (
    <PageWithHeader
      appBarHeader={<AppBarHeader title="Account Reactivated" />}
      footer={
        <LoadingButton
          fullWidth
          variant="contained"
          isLoading={!authStateHasLoaded}
          onClick={() => {
            history.push(RootPaths.HOME);
          }}
        >
          Continue
        </LoadingButton>
      }
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh", paddingBottom: 20 }}
        gap={4}
      >
        <Image src={cbhBunnyLogo} alt="Clipboard Health Bunny" width={100} />
        <Title component="h2">Your account has been reactivated!</Title>
        <Text>You can now log in to your account.</Text>
      </Stack>
    </PageWithHeader>
  );
}
