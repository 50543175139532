import { LoadingButton } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { zodResolver } from "@hookform/resolvers/zod";
// eslint-disable-next-line no-restricted-imports
import { getFirebaseSingleton } from "@src/lib/firebase/src";
import { isAxiosError } from "axios";
import { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import { RootPaths } from "../App/paths";
import FetchOtpButtonInDevelopmentMode from "../Debug/components/FetchOtpButtonInDevelopmentMode";
import { AppBarHeader, BackButtonLink, PageWithHeader, useToast } from "../lib";
import { useEditAccountReactivationRequest } from "./api/useEditAccountReactivationRequest";
import { enterEmailFormSchema, verifyOtpFormSchema } from "./Forms/schema";
import { VerifyEmailForm } from "./Forms/VerifyEmailForm";
import { VerifyOtpForm } from "./Forms/VerifyOtpForm";
import { ACCOUNT_REACTIVATION_SUCCESS_FULL_PATH } from "./paths";

const enterEmailFormId = "enter-email-form";
const verifyOtpFormId = "verify-otp-form";

export function ReactivateEmailPage() {
  const history = useHistory();
  const { showSuccessToast } = useToast();

  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const reactivationId = queryParams.get("reactivationId");
  const emailFromQuery = queryParams.get("email");
  const nextPath = queryParams.get("nextPath");

  const verifyOtpFormMethods = useForm({
    defaultValues: {
      otp: "",
    },
    resolver: zodResolver(verifyOtpFormSchema),
  });

  const {
    formState: { isSubmitting: verifyOtpIsSubmitting, isValid: verifyOtpFormIsValid },
    setError: verifyOtpSetError,
    setValue: verifyOtpSetValue,
  } = verifyOtpFormMethods;

  const verifyOtpFormRef = useRef<HTMLFormElement>(null);

  const enterEmailFormMethods = useForm({
    defaultValues: {
      email: emailFromQuery ?? "",
    },
    resolver: zodResolver(enterEmailFormSchema),
  });

  const {
    formState: {
      isSubmitting: enterEmailIsSubmitting,
      isValid: enterEmailFormIsValid,
      isSubmitSuccessful: enterEmailFormIsSubmitSuccessful,
    },
    watch: enterEmailWatch,
    setError: enterEmailSetError,
  } = enterEmailFormMethods;

  const email = enterEmailWatch("email");
  const emailProvided =
    isDefined(emailFromQuery) || (enterEmailFormIsSubmitSuccessful && email.trim() !== "");

  const { mutateAsync: updateAccountReactivationRequest } = useEditAccountReactivationRequest();

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Verify Email Address"
          leftCta={<BackButtonLink alwaysUseDefaultBackTo defaultBackTo={RootPaths.HOME} />}
        />
      }
      footer={
        emailProvided ? (
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            isLoading={verifyOtpIsSubmitting}
            disabled={!verifyOtpFormIsValid}
            form={verifyOtpFormId}
          >
            Verify
          </LoadingButton>
        ) : (
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            isLoading={enterEmailIsSubmitting}
            disabled={!enterEmailFormIsValid}
            form={enterEmailFormId}
          >
            Send OTP Code
          </LoadingButton>
        )
      }
    >
      {!emailProvided && (
        <FormProvider {...enterEmailFormMethods}>
          <VerifyEmailForm
            id={enterEmailFormId}
            onSubmit={async (formData) => {
              if (!isDefined(reactivationId)) {
                return;
              }

              try {
                await updateAccountReactivationRequest({
                  id: reactivationId,
                  email: formData.email,
                });
              } catch (error) {
                if (isAxiosError(error) && error.response?.status === 422) {
                  enterEmailSetError("email", {
                    message:
                      "We could not verify your email address. Please try again or contact support for manual verification.",
                  });
                } else {
                  enterEmailSetError("email", {
                    message: "We could not verify your email address. Please try again.",
                  });
                }
              }
            }}
          />
        </FormProvider>
      )}

      {emailProvided && (
        <FormProvider {...verifyOtpFormMethods}>
          <VerifyOtpForm
            ref={verifyOtpFormRef}
            id={verifyOtpFormId}
            email={email}
            onSubmit={async (formData) => {
              if (!isDefined(reactivationId)) {
                return;
              }

              try {
                const response = await updateAccountReactivationRequest({
                  id: reactivationId,
                  emailOtp: formData.otp,
                });

                if (response.data.attributes.emailVerified) {
                  if (isDefined(nextPath) && !response.data.attributes.phoneVerified) {
                    showSuccessToast("Your email address has been verified.");
                    history.push(nextPath);
                    return;
                  }

                  if (isDefined(response.data.attributes.firebaseToken)) {
                    await getFirebaseSingleton().signInWithCustomToken(
                      response.data.attributes.firebaseToken
                    );
                    history.push(ACCOUNT_REACTIVATION_SUCCESS_FULL_PATH);
                    return;
                  }
                }

                verifyOtpSetError("otp", {
                  message: "We could not verify your email address. Please try again.",
                });
              } catch (error) {
                if (isAxiosError(error) && error.response?.status === 401) {
                  verifyOtpSetError("otp", {
                    message: "The verification code you entered was incorrect. Please try again.",
                  });
                } else {
                  verifyOtpSetError("otp", {
                    message: "We could not verify your email address. Please try again.",
                  });
                }
              }
            }}
          />
          <FetchOtpButtonInDevelopmentMode
            email={email}
            onSuccess={(otp) => {
              verifyOtpSetValue("otp", otp);
              verifyOtpFormRef.current?.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
              );
            }}
          />
        </FormProvider>
      )}
    </PageWithHeader>
  );
}
