import { formatLongDate } from "@clipboard-health/date-time";
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Facility, Shift } from "@lib/interface";
import { DialogContent } from "@mui/material";
import { FacilityDetails } from "@src/app/dayView/facilityDetails";
import { ShiftItem } from "@src/app/dayView/shiftItem";
import { RootPaths } from "@src/appV2/App/paths";
import { ErrorPage } from "@src/appV2/Errors/ErrorPage";
import {
  APP_V2_USER_EVENTS,
  AppBarHeader,
  BackButtonLink,
  FullScreenDialog,
  PageWithHeader,
  SlideUpTransition,
} from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useOpenShifts } from "@src/appV2/OpenShifts/api/useOpenShifts";
import { useBookabilityStatus } from "@src/appV2/OpenShifts/ShiftAction";
import { ShiftCardLoadingPlaceholder } from "@src/appV2/Shifts/MyShifts/components/ShiftCardLoadingPlaceholder";
import { SupportContext } from "@src/appV2/support/constants";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { HCF_PROFILE_SOURCE } from "@src/constants";
import { ViewShiftPathParams } from "@src/ShiftView/params";
import React from "react";
import { useRouteMatch } from "react-router-dom";

export function ViewShiftPage() {
  const match = useRouteMatch<ViewShiftPathParams>();

  const { shiftId } = match.params;

  const facilityDetailsModalState = useModalState();

  const worker = useDefinedWorker();

  const {
    data: shiftsResponse,
    isError: isErrorOpenShifts,
    isLoading: isLoadingOpenShifts,
  } = useOpenShifts({ id: [shiftId] });

  const {
    data: shiftBookability,
    isError: isErrorBookability,
    isLoading: isLoadingBookability,
  } = useBookabilityStatus({
    shiftIds: [shiftId],
    workerId: worker.userId,
  });

  const shift = shiftsResponse?.openAgentShifts.find((s) => s.shiftId === shiftId);
  const isLoading = isLoadingOpenShifts || isLoadingBookability;
  const isError = isErrorOpenShifts || isErrorBookability || !shift;

  useLogEffect(
    APP_V2_USER_EVENTS.OPENED_SHARED_SHIFT,
    {
      shiftId,
      isError,
    },
    { enabled: !isLoading }
  );

  if (isLoading) {
    return (
      <PageWithHeader
        appBarHeader={<AppBarHeader title="Loading shift..." />}
        containerVariant="without-margin"
      >
        <ShiftCardLoadingPlaceholder />
      </PageWithHeader>
    );
  }

  if (isError) {
    return (
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Shift not found"
            leftCta={<BackButtonLink defaultBackTo={RootPaths.HOME} />}
          />
        }
        containerVariant="without-margin"
      >
        <ErrorPage
          title="This shift seems to have slipped away!"
          description="It might have been booked in a flash or expired. Good shifts go fast, but don't worry - new opportunities pop up all the time!"
          supportContext={SupportContext.SHARED_SHIFT_NOT_FOUND}
        />
      </PageWithHeader>
    );
  }

  return (
    <>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title={formatLongDate(shift?.start)}
            leftCta={<BackButtonLink defaultBackTo={RootPaths.HOME} />}
          />
        }
        containerVariant="without-margin"
      >
        <ShiftItem
          shift={shift as Shift}
          shiftBookability={shiftBookability?.data[0].attributes.bookability}
          onFacilityDetailsClick={() => facilityDetailsModalState.openModal()}
        />
      </PageWithHeader>

      {isDefined(shift.facility) && (
        <FullScreenDialog
          modalState={facilityDetailsModalState}
          TransitionComponent={SlideUpTransition}
        >
          <DialogContent sx={{ padding: 0 }}>
            <FacilityDetails
              onClose={() => facilityDetailsModalState.closeModal()}
              facility={shift.facility as Facility}
              shift={shift as Shift}
              hcfProfileSource={HCF_PROFILE_SOURCE.CALENDAR}
            />
          </DialogContent>
        </FullScreenDialog>
      )}
    </>
  );
}
