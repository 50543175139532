import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import {
  accountReactivationAttributesRequestSchema,
  accountReactivationAttributesResponseSchema,
  type AccountReactivationErrorResponse,
} from "../types";

const createAccountReactivationRequestSchema = z.object({
  data: z.object({
    type: z.literal("reactivation-request"),
    attributes: accountReactivationAttributesRequestSchema,
  }),
});

const createAccountReactivationResponseSchema = z.object({
  data: z.object({
    type: z.literal("reactivation-request"),
    id: z.string(),
    attributes: accountReactivationAttributesResponseSchema,
  }),
});

export type CreateAccountReactivationRequest = z.infer<
  typeof createAccountReactivationRequestSchema
>;
export type CreateAccountReactivationResponse = z.infer<
  typeof createAccountReactivationResponseSchema
>;
export type CreateAccountReactivationParams = z.infer<
  typeof accountReactivationAttributesRequestSchema
>;

export function useCreateAccountReactivationRequest(
  options: UseMutationOptions<
    CreateAccountReactivationResponse,
    AxiosError<AccountReactivationErrorResponse>,
    CreateAccountReactivationParams
  > = {}
) {
  return useMutation({
    mutationFn: async (data) => {
      const response = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/reactivation-requests`,
        data: {
          data: {
            type: "reactivation-request",
            attributes: data,
          },
        },
        requestSchema: createAccountReactivationRequestSchema,
        responseSchema: createAccountReactivationResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_ACCOUNT_REACTIVATION_REQUEST_FAILURE,
    },
    ...options,
  });
}
