import { z } from "zod";

const VALID_OTP_LENGTH = 6;
const INVALID_OTP_MESSAGE = `Please enter a valid ${VALID_OTP_LENGTH}-digit code`;

export const verifyOtpFormSchema = z.object({
  otp: z
    .string()
    .min(VALID_OTP_LENGTH, { message: INVALID_OTP_MESSAGE })
    .max(VALID_OTP_LENGTH, { message: INVALID_OTP_MESSAGE }),
});

export const enterPhoneNumberFormSchema = z.object({
  phoneNumber: z.string(),
});

export const enterEmailFormSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email address" }),
});
