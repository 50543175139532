import { z } from "zod";

export enum AccountReactivationMethod {
  PHONE = "phone",
  EMAIL = "email",
}

export const accountReactivationAttributesRequestSchema = z.object({
  phone: z.string().optional(),
  email: z.string().optional(),
  phoneOtp: z.string().optional(),
  emailOtp: z.string().optional(),
});

export const accountReactivationAttributesResponseSchema = z.object({
  phone: z.string().optional(),
  email: z.string().optional(),
  phoneVerified: z.boolean(),
  emailVerified: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const accountReactivationErrorResponseSchema = z.object({
  errors: z.array(
    z.object({
      status: z.string(),
      code: z.string(),
      title: z.string(),
    })
  ),
});

export type AccountReactivationErrorResponse = z.infer<
  typeof accountReactivationErrorResponseSchema
>;
